<template>
  <header id="vm-cola-header" class="vm-cola-header" :class="`${className}`">
    <div v-if="['vmColaLogin', 'vmColaOrder'].includes(routeName)" class="back-button">
      <div class="back" @click="backToShopping">
        <img src="/static/img/icons/icon-left-arrow.svg" />
      </div>
    </div>
    <template
      v-if="
        routeName &&
        [
          'vmColaOrder',
          'vmColaPayment',
          'vmColaPaymentSuccess',
          'vmColaPaymentSuccessFailed',
          'vmColaPaymentFailed',
          'vmColaLogin',
          'vendingMachineLogin',
        ].includes(routeName)
      "
    >
      <HeaderLogo />
    </template>
    <template v-else>
      <template v-if="header_banner">
        <img :src="header_banner" />
      </template>
      <template v-else>
        <!-- <img src="/static/img/vmcola-header 1.png" /> -->
        <HeaderLogo />
      </template>
    </template>
  </header>
</template>

<script>
import ls from '@/components/helpers/local-storage';

export default {
  name: 'Header',

  components: {
    HeaderLogo: () => import('./header-logo.vue'),
  },
  props: {
    className: {
      type: String,
      required: true,
      default: () => '',
    },
  },

  computed: {
    header_banner() {
      return this.$store.state.vmCola.cola_header_banner?.[0]?.images?.[0]?.url;
    },
    routeName() {
      return this.$route.name;
    },
  },

  methods: {
    async backToShopping() {
      const is_guest = await ls.get('is_guest');

      const promises = [await this.$store.dispatch('fetchCurrentUserCart', this)];

      if (is_guest) {
        promises.push(this.$store.commit('SET_ALREADY_SEND_CART', false));
      }

      Promise.all(promises).then(() => {
        this.$router.push('/vmcola/products');
      });
    },
    resetCart() {
      Promise.all([
        ls.remove('guest_cart_id'),
        ls.remove('latest_cart_id'),
        ls.remove('is_guest'),
        ls.remove('cart'),
        this.$store.commit('SET_CART_ID', null),
        this.$store.commit('SET_SELECTED_POINT', 0),
        this.$store.dispatch('resetCart', 0),
      ]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/sass/mixin-revamp';

.vm-cola-header {
  color: #000000;
  text-align: center;
  letter-spacing: normal;
  font-family: $brandon-regular;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  padding: 0;

  &.vmColaHome {
    height: 15.88vh;
  }

  &.vmColaOffline {
    margin-top: 20vw;
  }

  &.vmColaPayment {
    height: 12.7vh;
    background: #ffffff;
  }

  &.vmColaOrder,
  &.vmColaLogin {
    height: 12.7vh;
    background: #fff2f7;

    .back-button {
      position: absolute;
      left: 40px;

      display: flex;

      .back {
        width: 80px;
        height: 80px;
        border-radius: 100%;
        background-color: #000;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: none;
        }
      }

      img {
        max-height: 60px;
      }
    }
  }

  &.vmColaLogin {
    background: transparent;
  }

  h4 {
    font: 32px/37px $brandon-medium;
    letter-spacing: 6.1px;
    margin: 0;
    text-transform: uppercase;
    width: 100%;
  }

  a {
    color: #000000;
  }

  .icon-logo {
    max-width: 100%;
    width: 361px;
    margin-bottom: 33px;
  }

  .header-logo {
    margin-top: 50px;

    /deep/ .header-logo__title {
      margin-bottom: 5px;
    }
  }
}
</style>
