var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"vm-cola-header",class:("" + _vm.className),attrs:{"id":"vm-cola-header"}},[(['vmColaLogin', 'vmColaOrder'].includes(_vm.routeName))?_c('div',{staticClass:"back-button"},[_c('div',{staticClass:"back",on:{"click":_vm.backToShopping}},[_c('img',{attrs:{"src":"/static/img/icons/icon-left-arrow.svg"}})])]):_vm._e(),(
      _vm.routeName &&
      [
        'vmColaOrder',
        'vmColaPayment',
        'vmColaPaymentSuccess',
        'vmColaPaymentSuccessFailed',
        'vmColaPaymentFailed',
        'vmColaLogin',
        'vendingMachineLogin' ].includes(_vm.routeName)
    )?[_c('HeaderLogo')]:[(_vm.header_banner)?[_c('img',{attrs:{"src":_vm.header_banner}})]:[_c('HeaderLogo')]]],2)}
var staticRenderFns = []

export { render, staticRenderFns }